<template>
  <div class="common_page_table">
    <slot name="header" :pageNum="pageNum" :pageSize="pageSize" :render="render" />
    <div style="height: 15px"></div>
    <slot name="navBar" :pageNum="pageNum" :pageSize="pageSize" :render="render" />
    <div class="table-box">
      <el-table
        ref="eltable"
        :key="tableKey"
        :data="data"
        size="small"
        style="width: 100%"
        :header-cell-style="{
          borderColor: '#EBEEF5',
          background: '#FAFAFA',
          color: '#333',
          fontWeight: 'normal'
        }"
        :row-key="rowKey"
        :border="border"
        stripe
        highlight-current-row
        @selection-change="selectionChangeEvent"
        @current-change="currentChangeEvent"
        v-loading="listLoading"
        :summary-method="getSummaries"
        :show-summary="showSummary"
      >
        <slot />
      </el-table>
      <div v-if="showPage" class="pagination-container">
        <el-pagination
          :background="background"
          :current-page.sync="currentPage"
          :page-size.sync="pageSize"
          :layout="layout"
          :page-sizes="pageSizes"
          :total="total"
          v-bind="$attrs"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'
// import { scrollTo } from '@/utils/scroll-to'
export default {
  name: "MyTable",
  // components: { Pagination },
  props: {
    // 是否初始化加载分页数据
    isInit: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      default: function() {
        return [];
      }
    },
    // 表格行数据的 key 值取值字段名称
    rowKey: {
      type: String,
      default: "1"
    },
    border: {
      type: Boolean,
      default: true
    },
    // column 是否展示
    columnShow: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 是否展示分页
    showPage: {
      type: Boolean,
      default: true
    },
    // 每页数目表
    pageSizes: {
      type: Array,
      default() {
        return [10,15, 20, 30];
      }
    },
    // 辅助功能组件
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper"
    },
    // 是否为分页按钮添加背景色
    background: {
      type: Boolean,
      default: true
    },
    // 是否自动回到顶部
    autoScroll: {
      type: Boolean,
      default: true
    },
    // 加载状态
    listLoading: {
      type: Boolean,
      default: false
    },
    // 合计状态
    showSummary: {
      type: Boolean,
      default: false
    },
    // key集合
    keys: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    }
  },
  data() {
    return {
      tableKey: 0,
      pageSize: 15,
      pageNum: 1,
      total: 0
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.pageNum;
      },
      set(val) {
        this.$emit("update:pageNum", val);
      }
    }
  },
  watch: {},
  mounted() {
    if (this.isInit) {
      this.init();
    }
  },
  methods: {
    init(type) {
      // 如果 type 存在,判断是添加数据或者是删除数据
      // 处理临界点的页数自动新增或者减少
      if (type) {
        if (type === "delete") {
          if (this.data.length === 1 && this.pageNum > 1) {
            this.pageNum = this.pageNum - 1 || 1;
          }
        } else if (type === "add") {
          if (this.data.length === this.pageSize && this.pageNum > 1) {
            this.pageNum = this.pageNum + 1 || 1;
          }
        }
      }
      // 触发获取列表数据
      this.$emit("page", {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        render: this.render
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      console.log(this.keys)
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value)) && (index == 10 || index == 11 || index == 12)) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += '';
        } else {
          sums[index] = '-';
        }
      });

      return sums;
    },
    selectionChangeEvent(selection) {
      this.$emit("selection-change", selection);
    },
    currentChangeEvent(selection) {
      this.$emit("current-change", selection);
    },
    toggleRowSelection(row) {
      this.$nextTick(() => {
        this.$refs.eltable.toggleRowSelection(row, true);
      });
    },
    handleCurrentChange(page) {
      this.pageNum = page;
      this.init();
      if (this.autoScroll) {
        // scrollTo(0, 800)
      }
    },
    render(total) {
      this.total = total;
    },
    handleSizeChange(newPageSize) {
      this.pageSize = newPageSize;
      this.init();
      if (this.autoScroll) {
        // scrollTo(0, 800)
      }
    }
  }
};
</script>
<style scoped>
.pagination-container {
  padding: 32px 16px;
  text-align: center;
  background: #fff;
}
.table-box {
  background: #fff;
  padding: 20px 20px 0 20px;
}
</style>
